.first-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    z-index: 100;
}

.first-page button {
    border: 1px solid #ccc;
    padding: 6px 20px;
    border-radius: 5px;
    margin-top: 30px;
    background: transparent;
    margin-bottom: 50px;
}

.first-page-text {
    padding: 0 20px;
}

.chat-page * {
    box-sizing: border-box;
    z-index: 10;
}

.chat-page header {
    height: 44px;
    background: #2b2b2b;
    color: #fff;
    vertical-align: middle;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}


.icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 100%;
}

.chat-page header .nav-back {
    height: 100%;
    line-height: 44px;
    position: relative;
    width: 66px;
    font-size: 16px;
    font-weight: lighter;
    display: flex;
    align-items: center;
    padding-left: 8px;
    color: white;
    text-decoration: none;
}

.chat-page header .icon-back {
    height: 20px;
    width: 20px;
    background-image: url('../images/wechat/icon/back.svg');
}

.chat-page header h1 {
    font-size: 18px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0;
}


.chat-page header .nav-person {
    padding-right: 8px;
}

.chat-page header .icon-person {
    width: 23px;
    height: 21px;
    background-image: url('../images/wechat/icon/person.svg');
    float: right;
}

i {
    font-style: normal;
}

.chat-page main {
   font-size: 16px;
   background-color: #ebebeb;
   position: fixed;
   top: 44px;
   bottom: 50px;
   left: 0;
   right: 0;
   overflow-y: auto;
   padding-bottom: 10px;
}

.chat-page main::-webkit-scrollbar {
    display:none
}

.message-item {
    width: 100%;
    display: flex;
    margin-top: 8px;
}

.message-item .avatar{
    width: 40px;
    height: 40px;
    margin: 0 10px;
}

.message-bubble {

}

.message-item--left {
}

.message-item--right {
    flex-direction: row-reverse;
}

.message-bubble {
    /* border: 1px solid #ddd; */
    padding: 7px 10px;
    border-radius: 3px;
    max-width: 257px;
    position: relative;
}

.message-item--left .message-bubble {
    background-color: #fff;
}
.message-item--left .message-bubble::before {
    content: '';
    /* border-top: 1px solid #ddd;
    border-left: 1px solid #ddd; */
    width: 10px;
    height: 10px;
    background: #fff;
    position: absolute;
    top: 14px;
    left: -6px;
    transform: rotate(-45deg);
    border-radius: 0 0 20px 0;
}

.message-item--right .message-bubble {
    /* background-color: #afe56e;
    border-color: #7fb25a; */
    background-color: #95ec69;
    border-color: #87cd51;
}

.message-item--right .message-bubble::before {
    content: '';
    /* border-top: 1px solid #87cd51;
    border-left: 1px solid #87cd51; */
    width: 10px;
    height: 10px;
    background-color: #95ec69;
    position: absolute;
    top: 14px;
    right: -6px;
    transform: rotate(135deg);
    border-radius: 0 0 20px 0;
}

input, select, textarea, button {
    outline: 0;
}

.chat-page footer {
    position: fixed;
    bottom: 0;
    height: 50px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #f5f5f7;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-page footer .icon {
    width: 28px;
    height: 28px;
    margin-left: 10px;
}

.chat-page footer .icon:last-child {
    margin-right: 10px;
}

.chat-page footer .text-input{
    flex: 1;
    margin-left: 10px;
    padding-left: 8px;
    height: 36px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #f5f5f5;
    font-size: 16px;
    width: 100px;
}

.chat-page footer .icon-voice {
    background-image: url('../images/wechat/icon/voice.svg');
}

.chat-page footer .icon-plus {
    background-image: url('../images/wechat/icon/plus-cycle.svg');
}

.chat-page footer .icon-face{
    background-image: url('../images/wechat/icon/smile-face.svg');
}

.chat-page footer .send-button {
    background-color: #07c160;
    position: relative;
    display: block;
    margin-left: 6px;
    margin-right: 4px;
    padding: 6px 12px;
    box-sizing: border-box;
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    color: #FFFFFF;
    line-height: 1.2;
    border-radius: 4px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    overflow: hidden;
    border: 0;
}

.chat-page footer .send-button:active {
    color: #FFFFFF;
    background-color: #06AD56;
}
.message-select {
    height: 200px;
    bottom: -200px;
    position: fixed;
    width: 100%;
}

.show-selector .chat-page footer {
    bottom: 200px;
}

.show-selector main {
    bottom: 250px;
}

.show-selector .message-select {
    bottom: 0;
}

.chat-page footer, main, .message-select {
    transition: bottom .3s;
}

.message-select {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px;
}

.message-select .message-item {
    display: flex;
    justify-content: center;
}

/* cover-tips */
.cover-tips {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.mask {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .3);
    position: absolute;
    z-index: 100;
}

.hide {
    display: none;
}

.tips-container {
    width: 200px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    z-index: 101;
    display: flex;
    flex-direction: column;
    transform: translateY(-10%);
    padding: 20px;
}

.tips-text {
    width: 100%;
    line-height: 30px;
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
}

.tips-icons {
    display: flex;
    justify-content: space-around;
}

.icon-replay, .icon-share {
    width: 40px;
    height: 40px;
}

.icon-replay {
    background-image: url('../images/wechat/icon/replay.svg');
}

.icon-share {
    background-image: url('../images/wechat/icon/share.svg');
}